import { useAuthenticatedFetch } from "~/composables/useAuthenticatedFetch";

export interface Notification {
  data: {
    userId: string;
    notificationName: NOTIFICATION_TYPE;
  };
  id: string;
  readAt: string | null;
  type: string;
  createdAt: string;
}

export enum NOTIFICATION_TYPE {
  B2B_INVITATION_ACCEPTED = "B2BInvitationAccepted",
  EDUCATOR_PROMPT_NOTIFICATION = "EducatorPromptNotification",
}

export default function useNotifications () {
  const getAllNotifications =  async () => {
    return await useErrorHandling(async () => {
      const response = await $fetch<{
        data: Array<Notification>;
      }>('/api/auth/user/notifications');
      if (!response?.data) {
        throw new Error('Invalid response from server.');
      }

      return response.data;
    });
  };

  const getUnreadNotifications =  async () => {
    return await useErrorHandling(async () => {
      const response = await $fetch<{
        data: Array<Notification>;
      }>('/api/auth/user/notifications/unread');
      if (!response?.data) {
        throw new Error('Invalid response from server.');
      }

      return response.data;
    });
  };

  const getReadNotifications =  async () => {
    return await useErrorHandling(async () => {
      const response = await $fetch<{
        data: Array<Notification>;
      }>('/api/auth/user/notifications/read');
      if (!response?.data) {
        throw new Error('Invalid response from server.');
      }

      return response.data;
    });
  };

  const markAsRead = async (
    notificationId: string,
  ) => {
    return await useErrorHandling(async () => {
      await useAuthenticatedFetch(`/api/auth/user/notifications/mark-as-read/${notificationId}`, {
        method: "POST",
        credentials: 'include',
      });
    });
  };

  const markAsUnread = async (
    notificationId: string,
  ) => {
    return await useErrorHandling(async () => {
      await useAuthenticatedFetch(`/api/auth/user/notifications/mark-as-unread/${notificationId}`, {
        method: "POST",
        credentials: 'include',
      });
    });
  };

  const answerEducatorPrompt = async (
    notificationId: string,
    answer: string,
  ) => {
    return await useErrorHandling(async () => {

      await useAuthenticatedFetch(`/api/public/learning-app/user/answer-educator-prompt/${notificationId}`, {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify({ answer }),
      });
    });
  };

  return {
    getAllNotifications,
    getUnreadNotifications,
    getReadNotifications,

    answerEducatorPrompt,
    markAsRead,
    markAsUnread,
  };
}
