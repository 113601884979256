<script setup lang="ts">
import { useOnline } from '@vueuse/core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { GenericEvents } from '~/enums/tracking/tracking-events.enums';
import useEducatorPrompt from "~/composables/useEducatorPrompt";

const isOnline = useOnline();
const { dispatchMessage } = useSystemMessages();
const updateNoticeVisible = ref(false);
const { checkAndOpenEducatorPrompt } = useEducatorPrompt();

watch(isOnline, (newValue: boolean) => {
  if (newValue) {
    dispatchMessage('Back online.', 'success', 'Your browser is back online.');
  } else {
    dispatchMessage('You are offline.', 'warn', 'Your browser went offline, please check your internet connection.', 15000);
  }
});

useNuxtApp().hook('app:manifest:update', () => {
  if (updateNoticeVisible.value) {
    return;
  }

  usePVToastService().add({
    severity: 'info',
    summary: 'App Update',
    group: 'appUpdate',
    closable: false,
  });

  updateNoticeVisible.value = true;
});

useNuxtApp().hook('page:finish', () => {
  checkAndOpenEducatorPrompt();
});

const reloadPage = () => {
  useTracking().dispatchTrackingEvent(GenericEvents.AppUpdateButtonClicked);
  window.document.location.reload();
};

useHead({
  titleTemplate: titleChunk => {
    return titleChunk ? `${titleChunk} | Medmastery` : 'Medmastery';
  },
});

</script>

<template>
  <NuxtLayout>
    <SystemMessages />
    <DynamicDialog />

    <NuxtPage />

    <Toast
      group="appUpdate"
      position="bottom-left"
    >
      <template #container>
        <div class="p-4 flex gap-4">
          <FontAwesomeIcon
            class="text-24 mt-[calc((36px-24px)/2)]"
            :icon="['far', 'rocket-launch']"
          />

          <div class="flex flex-col gap-2">
            <div>
              <b>We've made some updates!</b> Refresh now for the best experience.
            </div>

            <div>
              <Button
                size="small"
                class="bg-neutral-80 border-0"
                @click="reloadPage"
              >
                <FontAwesomeIcon
                  class="mr-2"
                  icon="far fa-rotate"
                />
                <span class="font-bold">Update</span>
              </Button>
            </div>
          </div>
        </div>
      </template>
    </Toast>
  </NuxtLayout>
</template>
