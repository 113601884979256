import { useDialog } from "primevue/usedialog";
import useNotifications, { NOTIFICATION_TYPE } from "~/composables/useNotifications";
import EducatorPromptDialog from "~/components/EducatorPromptDialog/index.vue";

export default function useEducatorPrompt () {
  const { getUnreadNotifications } = useNotifications();
  const dialog = useDialog();
  const { checkAuthenticationStatus } = useAuthentication();

  const isPromptOpen = useState('isPromptOpen', () => false);
  const EDUCATOR_PROMPT_STORAGE_KEY = "lastEducatorPrompt";
  const TIME_BETWEEN_PROMPTS = 1000 * 60 * 60; // 1 hour

  const hasEnoughTimeElapsed = () => {
    const lastShown = parseInt(localStorage.getItem(EDUCATOR_PROMPT_STORAGE_KEY) || "0", 10);
    return Date.now() - lastShown >= TIME_BETWEEN_PROMPTS;
  };

  const checkAndOpenEducatorPrompt = async () => {
    if (isPromptOpen.value || !hasEnoughTimeElapsed() || !await checkAuthenticationStatus()) {
      return;
    }

    const unreadNotifications = await getUnreadNotifications();
    const educatorPrompt = unreadNotifications?.find(
      n => n.data.notificationName === NOTIFICATION_TYPE.EDUCATOR_PROMPT_NOTIFICATION,
    );

    if (educatorPrompt) {
      isPromptOpen.value = true;
      localStorage.setItem(EDUCATOR_PROMPT_STORAGE_KEY, Date.now().toString());

      dialog.open(EducatorPromptDialog, {
        props: {
          closeOnEscape: false,
          modal: true,
          showHeader: false,
          pt: {
            root: {
              class: 'relative flex items-center justify-center w-auto mx-auto h-auto',
            },
            content: {
              class: 'rounded-xl',
            },
          },
        },
        onClose: () => {
          isPromptOpen.value = false;
        },
        emits: {
          notificationId: educatorPrompt.id,
        },
      });
    }
  };

  return { checkAndOpenEducatorPrompt };
}