import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/opt/atlassian/pipelines/agent/build/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import _01_auth_client_UOBMAirzzr from "/opt/atlassian/pipelines/agent/build/plugins/01.auth.client.ts";
import backend_to_data_layer_client_VqfAKC1hp6 from "/opt/atlassian/pipelines/agent/build/plugins/backend-to-data-layer.client.ts";
import echo_client_DTUzXmgq1G from "/opt/atlassian/pipelines/agent/build/plugins/echo.client.ts";
import fontawesome_cn2c4tOOHz from "/opt/atlassian/pipelines/agent/build/plugins/fontawesome.ts";
import freshdesk_client_BOIRkKeoSs from "/opt/atlassian/pipelines/agent/build/plugins/freshdesk.client.ts";
import gtm_client_OzmBuHLRIb from "/opt/atlassian/pipelines/agent/build/plugins/gtm.client.ts";
import sanitize_html_client_G7muF7PpE0 from "/opt/atlassian/pipelines/agent/build/plugins/sanitize-html.client.ts";
import sentry_client_shVUlIjFLk from "/opt/atlassian/pipelines/agent/build/plugins/sentry.client.ts";
import tippy_client_WKoqvTKVZ4 from "/opt/atlassian/pipelines/agent/build/plugins/tippy.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  _01_auth_client_UOBMAirzzr,
  backend_to_data_layer_client_VqfAKC1hp6,
  echo_client_DTUzXmgq1G,
  fontawesome_cn2c4tOOHz,
  freshdesk_client_BOIRkKeoSs,
  gtm_client_OzmBuHLRIb,
  sanitize_html_client_G7muF7PpE0,
  sentry_client_shVUlIjFLk,
  tippy_client_WKoqvTKVZ4
]