import DOMPurify from 'dompurify';

const ALLOWED_TAGS = [
  'p', 'br', 'span', 'strong', 'b', 'em', 'i', 'u', 's',
  'ul', 'ol', 'li', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
  'sub', 'sup', 'a', 'table', 'thead', 'tbody', 'tr', 'td', 'th',
  'blockquote', 'pre', 'code', 'hr', 'img',
];

const ALLOWED_ATTR = ['href', 'target', 'rel', 'title', 'alt', 'style', 'src'];

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('sanitize-html', {
    beforeMount (el: HTMLElement, binding: DirectiveBinding) {
      el.innerHTML = sanitizeHTML(binding.value);
    },
    updated (el: HTMLElement, binding: DirectiveBinding) {
      if (binding.value !== binding.oldValue) {
        el.innerHTML = sanitizeHTML(binding.value);
      }
    },
  });
});

function sanitizeHTML (content: string): string {
  return DOMPurify.sanitize(content, {
    ALLOWED_TAGS,
    ALLOWED_ATTR,
  });
}