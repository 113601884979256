import * as Sentry from "@sentry/vue";
import type { SpanJSON } from '@sentry/types';

export default defineNuxtPlugin(app => {
  if (app.$config.public.env !== 'production') {
    console.warn('Sentry is only enabled in production environment.');
    return;
  }

  const transactionLogging = localStorage.getItem('transactionLogging') !== null;

  const spanFilter = (span: SpanJSON) => {
    if (span.op === 'http.client') {
      const excludes = [
        '.clarity.ms',
        'sst.medmastery.com',
        'bt.fraud0.com',
      ];

      for (const exclude of excludes) {
        if (span.data?.['http.url']?.includes(exclude)) {
          return null;
        }
      }
    }

    if (span.op === 'resource.script' || span.op === 'resource.link') {
      if (span.description?.includes('/_nuxt/')) {
        return null;
      }
    }

    if (span.description?.includes('fbevents:')) {
      return null;
    }

    return span;
  };

  const tracesSampleRate = parseFloat(app.$config.public.sentry.traces.sampleRate) || 0.25;

  Sentry.init({
    app: app.vueApp,
    dsn: "https://1690e342220e79fcb66c94b98d68a601@o4507412248395776.ingest.de.sentry.io/4507452801482832",
    release: `learning-app@${app.$config.public.gitInfo}`,
    environment: app.$config.public.env,
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    beforeSendTransaction: transaction => {
      if (transactionLogging) {
        console.debug('Sentry-Transaction', transaction);
      }

      return transaction;
    },
    beforeSendSpan: spanFilter,
    tracesSampleRate,
    tracePropagationTargets: ["app.medmastery.com/api"],
  });
});