<script setup lang="ts">
import type { DynamicDialogInstance } from 'primevue/dynamicdialogoptions';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const dialog = inject<Ref<DynamicDialogInstance>>('dialogRef');
const props = defineProps<{ notificationId: string }>();

const { answerEducatorPrompt } = useNotifications();
const answered = ref(false);

const handleAction = async (action: "yes" | "no" | "dismiss") => {
  if (action !== 'dismiss'){
    answered.value = true;
    setTimeout(() => {
      dialog?.value.close();
    }, 2000);
  }
  else {
    dialog?.value.close();
  }

  await answerEducatorPrompt(props.notificationId, action);
};

</script>

<template>
  <div class="max-w-[80vw] w-[345px] relative">
    <Button
      v-if="!answered"
      icon="times"
      class="absolute w-7 h-7 top-0 right-0 p-button-rounded p-button-text text-neutral-80 hover:bg-red-40 p-0"
      @click="handleAction('dismiss')"
    >
      <FontAwesomeIcon icon="times" />
    </Button>
    <div
      v-if="!answered"
      class="flex flex-col gap-2"
    >
      <div class="mb-3 flex justify-center">
        <img
          src="@/assets/images/gifs/medical-book.gif"
          alt="Checkmark circle"
          class="w-[120px] h-[120px]"
        >
      </div>
      <div class="text-22 font-bold text-center mb-2">
        Are you a medical educator?
      </div>
      <div class="text-16 text-neutral-70 text-center">
        We want to make our platform better for educators. Let us know if you’re teaching, and help us develop new tools and features for your work!
      </div>

      <div class="flex justify-between gap-2 mt-4">
        <Button
          rounded
          outlined
          class="text-neutral-80 border-neutral-90 font-bold w-1/2"
          label="Yes, I teach"
          @click="handleAction('yes')"
        />
        <Button
          rounded
          outlined
          class="text-neutral-80 border-neutral-90 font-bold w-1/2"
          label="No, I don't teach"
          @click="handleAction('no')"
        />
      </div>
    </div>
    <div
      v-else
      class="flex flex-col p-4 gap-2"
    >
      <div class="mb-3 flex justify-center">
        <img
          src="@/assets/images/gifs/confetti.gif"
          alt="Checkmark circle"
          class="w-[120px] h-[120px]"
        >
      </div>
      <div class="text-22 font-bold text-center mb-2">
        Thanks for your feedback!
      </div>
    </div>
  </div>
</template>