import { createGlobalState } from '@vueuse/core';
import type { User } from '~/composables/useAuthentication';
import { UserEvents } from '~/enums/tracking/tracking-events.enums';
import * as Sentry from "@sentry/vue";

interface SubscriptionLabels {
  [key: string]: string;
}

export const subscriptionLabels: SubscriptionLabels = {
  trial: 'Free account',
  basic: 'Basic membership',
  pro: 'Pro membership',
};

export const useUserStore = createGlobalState(
  () => {

    // State
    const state = reactive<{
      user: User | null;
      lastRefresh: number;
      loading: boolean;
      loaded: boolean;
    }>({
      user: null,
      lastRefresh: 0,
      loading: true,
      loaded: false,
    });

    // Getters
    const user = computed(() => state.user);

    const setUser = (user: User | null) => {
      if (state.user === null && user !== null) {
        trackAuthenticated(user);
      }

      if (user) {
        Sentry.setUser({
          id: user.id,
        });
      }

      state.user = user;
      state.lastRefresh = (new Date()).getTime();
      state.loaded = true;
    };

    const initials = computed(() => {
      let firstNameInitial = 'M';
      let lastNameInitial = 'M';

      if (state.user?.firstName && state.user.firstName.length >= 1) {
        firstNameInitial = state.user.firstName[0];
      }

      if (state.user?.lastName && state.user.lastName.length >= 1) {
        lastNameInitial = state.user.lastName[0];
      }

      return `${firstNameInitial}${lastNameInitial}`;
    });

    const name = computed(() => {
      if (!state.user) {
        return '';
      }

      if (state.user.firstName && state.user.lastName) {
        return `${state.user.firstName} ${state.user.lastName}`;
      } else if (state.user.firstName) {
        return state.user.firstName;
      } else if (state.user.lastName) {
        return state.user.lastName;
      } else {
        return state.user.email.replace(/@.*/, '');
      }
    });

    const trackAuthenticated = (user: User) => {
      useTracking().dispatchTrackingEvent(UserEvents.Authenticated, {
        user_id: user.traceId,
        user_status: 'logged_in',
        user_membership: user.subscription?.type || 'trial',
        user_country: user.country,
        user_profession: user.professionalTrack,
        user_specialty: user.specialty,
        user_experience: user.yearsOfExperience,
        user_email: user.email,
      });
    };

    return {
      user,
      initials,
      name,
      setUser,
      isLoading: computed(() => state.loading),
      isLoaded: computed(() => state.loaded),
    };
  },
);